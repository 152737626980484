<template>
  <div class="income-call-container" :class="{ 'income-call-container-show': show }">
    <v-card class="income-call-dialog">
      <v-img
        class="white--text align-end"
        height="250"
        :src="visitor.avatar"
      >
        <v-card-title style="background-color: rgba(0,0,0,0.5);">
          <div style="font-size: smaller">неизвестный под ником</div>
          <div>"{{ visitor.nickname }}"</div>
        </v-card-title>
      </v-img>
      <v-card-subtitle>
        {{ visitor.app }}
      </v-card-subtitle>
      <v-card-text>

      </v-card-text>
      <v-card-actions>
        <v-btn x-large outlined @click="onDeclineCallClick">Отклонить</v-btn>
        <v-spacer />
        <v-btn color="yellow" x-large @click="onAcceptCallClick">Принять</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ringAudio: null
    }
  },
  computed: {
    visitor() {
      return (this.incomingCall || {}).visitor || {}
    },
    incomingCall() {
      return this.$store.getters['commutator/incomingCall']
    },
    show() {
      return !!this.incomingCall
    }
  },
  watch: {
    incomingCall(val) {
      val ? this.startRing() : this.stopRing()
    }
  },
  mounted() {
    this.ringAudio = new Audio(require('@cpanel/assets/sound/ringtone2.mp3'))
    this.ringAudio.loop = true
    this.incomingCall ? this.startRing() : this.stopRing()
  },
  methods: {
    startRing() {
      this.ringAudio.play()
    },
    stopRing() {
      if (this.ringAudio.played) this.ringAudio.pause()
      this.ringAudio.currentTime = 0
    },
    onAcceptCallClick() {
      this.$store.dispatch('commutator/acceptCall')
      this.$nextTick(() => { this.$router.push('/videochat') })
    },
    onDeclineCallClick() {
      this.$store.dispatch('commutator/declineCall')
    }
  }
}
</script>

<style scoped>
  .income-call-container {
    position: fixed;
    width: 100%;
    height: 100%;
    max-height: 100vh;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 999;
    display: none;
  }
  .income-call-container-show {
    display: flex;
  }

  .income-call-dialog {
    width: 80%;
    display: flex;
    flex-direction: column;
    max-width: 374px;
  }
  @media screen and (max-width: 768px) {
    .income-call-dialog {
      width: 100%!important;
      height: 100%!important;
      max-width: 768px!important;
    }
  }
</style>
