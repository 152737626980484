<template>
  <v-select
    v-model="place"
    :items="places"
    item-text="name"
    item-value="_id"
    outlined
    no-data-text="Нет данных"
    persistent-hint
    return-object
    v-bind="$attrs"
    :disabled="disabled || $apollo.queries.places.loading"
    :loading="$apollo.queries.places.loading"
    @change="onPlaceChanged"
  />
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      place: null
    }
  },
  apollo: {
    places: {
      query: require('@cpanel/graphql/place-list.gql'),
      result(ApolloQueryResult, key) { this.onPlaceReceived(ApolloQueryResult.data[key]) },
      error(err) { this.$store.dispatch('dialog/error', err) }
    }
  },
  mounted() {
    this.place = this.$store.getters['user/place']
  },
  methods: {
    onPlaceChanged() {
      this.$store.dispatch('user/setPlace', this.place)
    },
    onPlaceReceived(places) {
      if (places && places.length && !this.place) {
        this.place = places[0]
        this.onPlaceChanged()
      }
    }
  }
}
</script>
