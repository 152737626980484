<template>
  <div @click="switchPlay">
    <video ref="localVideo" style="width: 100%; height: 100%; object-fit: contain;" autoplay muted />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    stream() {
      return this.$store.getters['preview/stream']
    },
    playing() {
      return this.$store.getters['preview/playing']
    }
  },
  watch: {
    value(val) {
      this.setPlaying(val)
    },
    stream(stream) {
      this.$refs['localVideo'].srcObject = stream
    }
  },
  mounted() {
    this.setPlaying(this.value)
    if (this.stream) {
      this.$refs['localVideo'].srcObject = this.stream
    }
  },
  methods: {
    setPlaying(val) {
      val ? this.play() : this.pause()
    },
    async switchPlay() {
      await (this.playing ? this.pause : this.play)()
      this.$emit('input', this.playing)
    },
    async play() {
      if (this.loading) return
      this.loading = true
      this.$emit('loading', this.loading)
      try {
        await this.$store.dispatch('preview/play')
      } finally {
        this.loading = false
        this.$emit('loading', this.loading)
      }
    },
    async pause() {
      if (this.loading) return
      this.loading = true
      this.$emit('loading', this.loading)
      try {
        await this.$store.dispatch('preview/pause')
      } finally {
        this.loading = false
        this.$emit('loading', this.loading)
      }
    }
  }
}
</script>

<style scoped>

</style>
