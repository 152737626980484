<template>
  <div class="video-box-container" @click="onConnectClick">
    <video ref="video" class="black" autoplay />
    <div v-if="connected && !publishers.length" class="white-noise" />
  </div>
</template>

<script>
import { VideoroomApiFactory } from '@common/vue-plugins/janus/vue-plugin'

export default {
  props: {
    roomId: { type: String, default: null },
    pin: { type: String, default: null }
  },
  data() {
    return {
      loading: false,
      connected: false,
      videoroomApi: null,
      publishers: []
    }
  },
  created() {
    this.videoroomApi = VideoroomApiFactory.getInstance()
    this.videoroomApi.on(this.videoroomApi.EVENTS.ROOM_PUBLISHER_IN, this.onPublisherIn.bind(this))
    this.videoroomApi.on(this.videoroomApi.EVENTS.ROOM_PUBLISHER_OUT, this.onPublisherOut.bind(this))
  },
  beforeDestroy() {
    this.videoroomApi.disconnect()
  },
  methods: {
    async onConnectClick() {
      this.loading = true
      try {
        this.connected ? await this.disconnect() : await this.connect()
      } finally {
        this.loading = false
      }
    },
    async connect() {
      if (this.connected) return
      if (!this.roomId) {
        console.log('No room id specified')
        return
      }

      try {
        const data = await this.videoroomApi.joinRoom({ roomId: this.roomId, pin: this.pin })
        this.connected = true
        if (data.publishers.length) {
          this.publishers = [...data.publishers]
          this.videoroomApi.subscribeTo(this.publishers[0].id, { htmlVideo: this.$refs['video'] })
        } else {
          this.$toast.warning('Видеотрансляция не ведется. Попробуйте позже')
        }
      } catch (e) {
        this.$toast.error(e)
      }
    },
    async disconnect() {
      if (!this.connected) return
      try {
        this.publishers = []
        this.connected = false
        await this.videoroomApi.disconnect()
      } catch (e) {
        this.$toast.error(e)
      }
    },
    onPublisherIn({ detail: publishers }) {
      this.publishers = [...publishers]
      if (this.publishers.length > 1) return
      this.videoroomApi.subscribeTo(this.publishers[0].id, { htmlVideo: this.$refs['video'] })
    },
    onPublisherOut({ detail: publisherId }) {
      const idx = this.publishers.findIndex(el => (el.id === publisherId))
      if (idx >= 0) this.publishers.splice(idx, 1)
    }
  }
}
</script>

<style>
  .video-box-container {
    position: relative;
    cursor: pointer;
    text-align: center;
  }
  .video-box-container video {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .video-box-container .white-noise {
    position: absolute;
    background-image: url(./../../assets/img/white-noise.gif);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
</style>
