<template>
  <div style="width: 100%;">
    <v-timeline dense clipped>

      <v-timeline-item
        fill-dot
        class="mb-12"
        color="orange"
        large
      >
        <template v-slot:icon>
          <v-icon>mdi-video</v-icon>
        </template>
        <h3 class="pt-3">
          Последние конференции
          <v-btn icon @click="refresh"><v-icon>mdi-refresh</v-icon></v-btn>
        </h3>
      </v-timeline-item>

      <v-slide-x-transition group>
        <v-timeline-item
          v-for="conference in conferences"
          :key="conference._id"
          class="mb-4"
          :color="conference.endDate ? 'green' : 'pink'"
          small
        >
          <v-row class="pt-1">
            <v-col cols="3" class="d-flex align-center">
              <v-chip
                class="white--text ml-0"
                :color="conference.endDate ? 'primary' : 'pink'"
                label
                small
                v-text="formatStartDate(conference.startDate)"
              />
            </v-col>
            <v-col cols="6">
              <strong>{{ (conference.agent || {}).name }}</strong>
              <div class="caption">
                {{ (conference.place || {}).name }}
              </div>
            </v-col>
            <v-col cols="3" class="d-flex align-center justify-end">
              <v-chip class="white--text ml-0" :color="conference.endDate ? 'green' : 'pink'" label small>
                <v-icon small class="pr-1">mdi-clock-time-three-outline</v-icon>
                {{ formatDuration(conference) }}
              </v-chip>
            </v-col>
          </v-row>
        </v-timeline-item>
      </v-slide-x-transition>
    </v-timeline>
  </div>
</template>

<script>
export default {
  data() {
    return {
      error: false
    }
  },
  apollo: {
    conferences: {
      query: require('@/graphql/conference-list.gql'),
      error(err) { this.error = err }
    }
  },
  methods: {
    async refresh() {
      this.error = false
      try {
        await this.$apollo.queries.conferences.refetch()
      } catch (e) {
        this.$toast.error(e)
      }
    },
    formatStartDate(dt) {
      return this.$moment(new Date(dt)).calendar()
    },
    formatDuration(conference) {
      if (!conference.endDate) return 'еще идет'
      const date1 = this.$moment(new Date(conference.startDate))
      const date2 = this.$moment(conference.endDate ? new Date(conference.endDate) : Date.now())
      return this.$moment.duration(date1.diff(date2)).humanize({ ss: 1 })
    }
  }
}
</script>

<style scoped>

</style>
