<template>
  <v-container>
    <v-row>
      <v-col>
        <v-covering-map v-if="false" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-agents-online />
      </v-col>
    </v-row>
    <v-row v-if="false">
      <v-col>
        <v-visitors-online />
      </v-col>
    </v-row>
    <v-row v-if="false">
      <v-col cols="6" class="blue lighten-5">
        <v-last-conferences />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VCoveringMap from './widgets/coveringMap'
import VAgentsOnline from './widgets/agentsOnline'
import VVisitorsOnline from './widgets/visitorsOnline'
import VLastConferences from './widgets/lastConferences'

export default {
  components: { VCoveringMap, VAgentsOnline, VVisitorsOnline, VLastConferences }
}
</script>

<style scoped>

</style>
