<template>
  <div class="home">
    <v-toolbar
      dense
      color="primary"
      dark
      extended
      extension-height="300"
      flat
    >
      <v-toolbar-title slot="extension" class="mx-auto pa-3">
        <span>
          Приветствуем в
        </span>
        <div class="text-h4">{{ $store.getters['app/name'] }}!</div>
        <p class="pt-4 subtitle-1" style="max-width: 400px; white-space: normal;">
          повысьте конверсию сайта, общайтесь с посетителями прямо из браузера
        </p>
        <div>
          <v-text-field
            v-if="integrationId"
            ref="codeInput"
            :value="integrationCode"
            class="caption"
            readonly
            solo
            persistent-hint
            hint="Добавьте этот код на сайт перед закрывающимся тэгом </body>"
            append-icon="mdi-content-copy"
            @click:append="copyCode"
          />
        </div>
      </v-toolbar-title>
    </v-toolbar>
    <v-dashboard />
  </div>
</template>

<script>
import VDashboard from './dashboard/dashboard'
const CDN = process.env.VUE_APP_CDN_URL

export default {
  components: { VDashboard },
  computed: {
    integrationId() {
      return this.$store.getters['user/integrationId']
    },
    integrationCode() {
      const src = CDN + 'ml-bootstrap.js'
      const code = this.integrationId
      return `<script src="${src}" data-magiclink-id="${code}" async><\/script>`
    }
  },
  methods: {
    copyCode() {
      const textToCopy = this.$refs.codeInput.$el.querySelector('input')
      textToCopy.select()
      document.execCommand('copy')
      textToCopy.selectionEnd = textToCopy.selectionStart
      this.$toast.success('Текст скопирован в буфер обмена')
    }
  }
}
</script>

<style scoped>

</style>
