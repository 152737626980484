<template>
  <v-dialog
    v-model="value"
    width="auto"
    max-width="600"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card class="d-flex flex-column">
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text class="flex-grow-1">{{ message }}</v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          v-for="(button, index) in buttons"
          :key="index"
          v-bind="button.$attrs"
          @click="onClick(button)"
        >
          {{ button.text }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      value: false
    }
  },
  computed: {
    show() {
      return this.$store.getters['dialog/show']
    },
    title() {
      return this.$store.getters['dialog/title']
    },
    message() {
      return this.$store.getters['dialog/message']
    },
    buttons() {
      return this.$store.getters['dialog/buttons']
    }
  },
  watch: {
    show(val) {
      this.value = val
    },
    value(val) {
      if (!val) this.$store.dispatch('dialog/hide')
    }
  },
  methods: {
    onClick(button) {
      this.$store.dispatch('dialog/setResult', button.result || null)
      this.value = false
    }
  }
}
</script>

<style scoped>

</style>
