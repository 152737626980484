<template>
  <v-navigation-drawer
    v-model="drawer"
    app
    class="blue-grey darken-4"
    dark
    persistent
    fixed
    :mini-variant="miniVariant"
    :mini-variant-width="68"
  >
    <v-toolbar flat class="transparent">
      <template v-if="!miniVariant">
        <v-app-bar-nav-icon>
          <v-progress-circular v-if="loading" :size="24" indeterminate color="white" />
          <v-icon v-else large color="orange">camera</v-icon>
        </v-app-bar-nav-icon>
        <v-toolbar-title>
          <h5 v-text="$store.getters['app/name']" />
        </v-toolbar-title>
        <v-spacer />
      </template>
      <v-btn icon @click.stop="miniVariant = !miniVariant">
        <v-icon v-html="miniVariant ? 'chevron_right' : 'chevron_left'" />
      </v-btn>
    </v-toolbar>

    <v-divider />
    <v-list dense class="app-nav-list" :class="{'list-mini' : miniVariant}">
      <v-list-item link>
        <v-list-item-icon>
          <router-link to="/" tag="span"><v-icon>home</v-icon></router-link>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <router-link to="/" tag="span">Панель управления</router-link>
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action v-if="false">
          <v-menu v-if="!miniVariant" right offset-x>
            <template v-slot:activator="{ on }">
              <v-btn text icon v-on="on">
                <v-icon size="18">settings</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item to="/settings/webapp">
                <v-list-item-title>Сайты и виджеты</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <v-divider />

    <template v-for="(menuItem, sector) in menu">
      <v-list :key="`${sector}-list`" subheader dense class="app-nav-list" :class="{'list-mini' : miniVariant}">
        <v-subheader>{{ menuItem.title.toUpperCase() }}</v-subheader>
        <template v-for="item in menuItem.items">
          <v-tooltip :key="item.title" right :disabled="!miniVariant">
            <template v-slot:activator="{ on, attrs }">
              <v-list-item
                :key="item.icon"
                :to="item.link"
                exact
                v-bind="attrs"
                v-on="on"
              >
                <v-list-item-icon>
                  <template v-if="item.badge">
                    <v-badge
                      color="green"
                      :content="item.badge"
                    >
                      <v-icon v-html="item.icon" />
                    </v-badge>
                  </template>
                  <v-icon v-else v-html="item.icon" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.title" />
                </v-list-item-content>
              </v-list-item>
            </template>
            <span v-text="item.title" />
          </v-tooltip>
        </template>
      </v-list>
      <v-divider :key="`${sector}-divider`" />
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    value: {
      type: Boolean,
      default: null
    }
  },
  data() {
    return {
      drawer: this.value,
      miniVariant: false,
      loading: false,
      docTitle: window.document.title,
      titleBlinkInterval: null
    }
  },
  computed: {
    ...mapGetters({
      chatUnreadCount: 'chat/unreadMessageCount'
    }),
    menu() {
      return this.$store.getters['app/menu']
    }
  },
  watch: {
    value(newValue) {
      this.drawer = newValue
    },
    drawer(newValue) {
      this.$emit('input', newValue)
    },
    chatUnreadCount(val) {
      this.setChatBadge(val)
      if (!val) {
        if (this.titleBlinkInterval) clearInterval(this.titleBlinkInterval)
        this.titleBlinkInterval = null
        window.document.title = this.docTitle
      } else {
        if (!this.titleBlinkInterval) {
          this.docTitle = window.document.title
          this.titleBlinkInterval = setInterval(() => {
            window.document.title = (Math.floor(Date.now() / 1000) % 2) ? `Новый чат (${val})` : this.docTitle
          }, 1000)
        }
      }
    },
    $route(to) {
      this.$nextTick(() => { this.docTitle = window.document.title })
    }
  },
  methods: {
    ...mapActions({ setChatBadge: 'app/setChatMenuBadge' })
  }
}
</script>

<style scoped>
  .app-nav-list {
    padding: 0;
  }
  .app-nav-list >>> .v-list-item {
    padding: 0 0 0 8px;
  }
  .app-nav-list >>> .v-list-item__icon {
    margin: 4px;
    margin-right: 16px!important;
  }
  .app-nav-list >>> .v-list-item__content {
    padding: 0;
  }
  .app-nav-list >>> .v-list-item__title {
    font-weight: normal!important;
  }
  .app-nav-list >>> .v-list-item__action {
    border-left: 0.3px solid rgba(255, 255, 255, .2);
    margin: 0;
  }

  .list-mini {
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  }
  .list-mini >>> .v-list__tile {
    padding: 0 8px;
  }
  .list-mini >>> .v-subheader {
    display: none;
  }
</style>
