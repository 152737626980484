<template>
  <div style="width: 100%;">
    <h2>Посетители на сайте</h2>
    <v-toolbar flat>
      <v-icon>mdi-dots-vertical</v-icon>
      <v-btn icon @click="refresh"><v-icon>mdi-refresh</v-icon></v-btn>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="visitorsOnline"
      :items-per-page="50"
      :loading="$apollo.queries.visitorsOnline.loading"
      loading-text="Получение данных..."
      :no-data-text="error ? 'ошибка получения данных' : 'нет данных'"
      class="app-conference-table"
    >
    </v-data-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        { text: '##', value: 'visitorId', width: '120px', divider: true },
        { text: 'Платформа', value: 'platform' },
        { text: 'Приложение', value: 'app' },
        { text: 'Подключен', value: 'createdAt' }
      ],
      error: false
    }
  },
  apollo: {
    visitorsOnline: {
      query: require('@cpanel/graphql/visitors-online.gql'),
      error(err) { this.error = err }
    }
  },
  methods: {
    async refresh() {
      this.error = false
      try {
        await this.$apollo.queries.visitorsOnline.refetch()
      } catch (e) {
        this.$toast.error(e)
      }
    }
  }
}
</script>

<style scoped>

</style>
