<template>
  <v-app>
    <nav-left v-if="!videochatActive" v-model="leftDrawer" />
    <app-toolbar
      v-if="!videochatActive"
      @click:side-icon="leftDrawer = !leftDrawer"
      @click:right-drawer="rightDrawer = !rightDrawer"
    />

    <v-main>
      <transition-group :name="$route.meta.transition === false ? null : 'slide-x-transition'">
        <router-view :key="$route.fullPath" />
      </transition-group>
    </v-main>

    <v-app-dialog v-if="!videochatActive" v-model="appDialog" />
    <v-income-call-dialog v-if="!videochatActive" />

  </v-app>
</template>

<script>
import VAppDialog from '@cpanel/components/app-dialog'
import VIncomeCallDialog from '@cpanel/components/income-call/vIncomeCallDialog'
import NavLeft from './console/nav-left'
import AppToolbar from './console/toolbar'

export default {
  components: { NavLeft, AppToolbar, VAppDialog, VIncomeCallDialog },
  data() {
    return {
      leftDrawer: null,
      rightDrawer: false
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters['user/isLoggedIn']
    },
    appDialog() {
      return this.$store.getters['app/alert']
    },
    videochatActive() {
      return this.$store.getters['videochat/caller']
    }
  },
  watch: {
    isLoggedIn() {
      this.validateLogin()
    }
  },
  mounted() {
    this.validateLogin()
  },
  methods: {
    validateLogin() {
      this.isLoggedIn || this.$router.replace('/login')
    }
  }
}
</script>

