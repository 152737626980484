<template>
  <v-dialog
    v-model="dialog"
    :close-on-content-click="false"
    width="auto"
    max-width="600"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <template v-slot:activator="{ on, attrs }">
      <div>
        <v-chip :color="isOnline ? 'green' : 'black'" v-bind="attrs" v-on="on">
          <v-icon class="pr-2">{{ !isOnline ? 'mdi-wifi-off' : preview ? 'mdi-video-outline' : 'mdi-video-off-outline' }}</v-icon>
          {{ isOnline ? 'Вы на связи' : 'Вы офлайн' }}
        </v-chip>
      </div>
    </template>
    <v-card class="d-flex flex-column">
      <v-card-title class="primary headline white--text">
        <div>Управление подключением</div>
        <v-spacer />
        <v-btn icon text :disabled="loading" @click="dialog = false">
          <v-icon class="white--text">close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="flex">
        <v-container fluid>
          <v-row>
            <v-col>
              <p class="body-1 text-center">
                Укажите свое местоположение и выходите на связь.
                Тогда посетители сайта смогут связаться с вами через ВидеоВиджет.
              </p>
            </v-col>
          </v-row>
          <v-row class="justify-center">
            <v-col cols="12" sm="7">
              <v-place-input :disabled="isOnline" label="Ваша локация" hide-details />
            </v-col>
            <v-col cols="12" sm="5" class="text-center">
              <v-btn
                :color="isOnline ? 'success' : 'success'"
                class="white--text"
                :disabled="loading"
                :loading="loading"
                @click="switchOnline"
              >
                <v-icon class="pr-2">{{ isOnline ? 'mdi-phone-off' : 'mdi-phone' }}</v-icon>
                {{ isOnline ? 'Отключиться' : 'Подключиться' }}
              </v-btn>
              сейчас {{ isOnline ? 'вы на связи' : 'вы офлайн' }}
            </v-col>
          </v-row>
          <v-row justify="center" class="indigo lighten-5 py-4 mt-4">
            <v-col cols="12" sm="6" class="d-flex justify-center">
              <v-preview-room
                v-if="isOnline"
                v-model="preview"
                style="width: 160px; height: 160px; background-color: black; border-radius: 12px; overflow: hidden;"
                @loading="loading = $event"
              />
              <div
                v-else
                class="d-flex justify-center align-center"
                style="width: 160px; height: 160px; background-color: #000000AA; border-radius: 12px; overflow: hidden; color: white;"
              >
                не в сети
              </div>
            </v-col>
            <v-col cols="12" sm="6">
              <v-switch
                v-model="preview"
                inset
                :disabled="loading || !isOnline"
                :loading="loading"
                label="Приглашающая видеотрансляция"
                class="mt-0"
              />
              <div class="caption" style="min-height: 100px;">
                <p v-if="!isOnline">
                  Вы не в сети. Посетители Вас не видят и не могут связаться с вами через ВидеоВиджет.
                </p>
                <p v-if="isOnline && preview">
                  Все посетители сайта видят ваше изображение (без звука). Они понимают, что вы "настоящий" и активнее идут на контакт.
                </p>
                <p v-if="isOnline && !preview">
                  Вы на связи, но ваше видео не транслируется. Посетители не видят вас через ВидеоВиджет, пока Вы не ответите на их звонок.
                </p>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider />
    </v-card>
  </v-dialog>
</template>

<script>
import VPlaceInput from '@cpanel/components/place-input/vPlaceInput'
import VPreviewRoom from '@cpanel/components/preview-room/vPreviewRoom'

export default {
  components: { VPlaceInput, VPreviewRoom },
  data() {
    return {
      dialog: false,
      preview: true,
      loading: false
    }
  },
  computed: {
    isOnline() {
      return this.$store.getters['commutator/connected']
    }
  },
  methods: {
    async registerAgent() {
      this.loading = true
      try {
        await this.$store.dispatch('commutator/register')
        await (new Promise(resolve => setTimeout(resolve, 3000)))
        this.$toast.success('Вы на связи')
      } catch (e) {
        this.$store.dispatch('dialog/error', e)
      }
      this.loading = false
    },

    async unregisterAgent() {
      this.loading = true
      try {
        await this.$store.dispatch('commutator/unregister')
        await (new Promise(resolve => setTimeout(resolve, 3000)))
      } catch (e) {
        this.$toast.error(e)
      }
      this.loading = false
    },

    switchOnline() {
      this.isOnline ? this.unregisterAgent() : this.registerAgent()
    }
  }
}
</script>

<style scoped>

</style>
