<template>
  <v-app-bar
    app
    flat
    color="primary"
    dark
    dense
  >
    <v-app-bar-nav-icon
      class="hidden-lg-and-up"
      @click.stop="$emit('click:side-icon')"
    />
    <v-toolbar-title>{{ pageTitle }}</v-toolbar-title>
    <v-spacer />

    <toolbar-status-button />
    <v-menu>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon color="white" size="32">mdi-account-circle</v-icon>
        </v-btn>
      </template>
      <v-list class="pa-0" light>
        <v-list-item>
          <v-list-item-avatar>
            <v-avatar class="primary" size="40px">
              <v-icon dark>person</v-icon>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ user.name }}</v-list-item-title>
            <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider />

        <v-list-item key="profile" link :to="`/user/${user._id}`">
          <v-list-item-action>
            <v-icon>person</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Мой профиль</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />

        <v-list-item key="lock_open" @click="logout">
          <v-list-item-action>
            <v-icon>lock_open</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Выйти</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import ToolbarStatusButton from '@cpanel/components/toolbar-status-button/toolbar-status-button'

export default {
  components: { ToolbarStatusButton },
  data() {
    return {
      pageTitle: '',
      drawer: true,
      menuItems: []
    }
  },
  computed: {
    user() {
      return this.$store.getters['user/user'] || { email: null }
    }
  },
  watch: {
    $route(to) {
      this.updateRoute(to)
    }
  },
  mounted() {
    this.updateRoute(this.$route)
  },
  methods: {
    updateRoute(route) {
      this.pageTitle = (route.meta || {}).title || ''
      document.title = process.env.VUE_APP_APP_NAME + ' :: ' + this.pageTitle
    },
    async logout() {
      await this.$store.dispatch('user/logout')
    }
  }
}
</script>

<style scoped>

</style>
