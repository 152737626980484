<template>
  <div style="width: 100%;">
    <h2>Команда на связи</h2>
    <v-toolbar flat>
      <v-icon>mdi-dots-vertical</v-icon>
      <v-btn icon @click="refresh"><v-icon>mdi-refresh</v-icon></v-btn>
    </v-toolbar>
    <v-data-iterator
      :headers="headers"
      :items="agentsOnline"
      :items-per-page="-1"
      item-key="_id"
      fixed-header
      :loading="$apollo.queries.agentsOnline.loading"
      loading-text="Получение данных..."
      :no-data-text="error ? 'при получении данных произошла ошибка' : 'Нет активных подключений'"
      :class="{ 'apollo-error': !!error }"
    >
      <template v-slot:default="{ items }">
        <v-row>
          <v-col
            v-for="item in items"
            :key="item._id"
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <v-card class="d-flex black" style="min-height: 240px; border-radius: 20px; overflow: hidden">
              <div class="white--text pa-5" style="position: absolute; width: 100%; z-index: 1;">
                <div>{{ item.name }}</div>
                <div class="subtitle-2">{{ item.place.name }}</div>
              </div>
              <video-box
                class="flex-fill"
                :room-id="item.roomId"
                :pin="item.pin"
              />
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
  </div>
</template>

<script>
import VideoBox from '@cpanel/components/video-box/video-box'

export default {
  components: { VideoBox },
  data() {
    return {
      headers: [
        { text: 'Имя', value: 'name', width: '120px', divider: true },
        { text: 'Логин', value: 'login' },
        { text: 'Комната #', value: 'roomId' },
        { text: 'pin', value: 'pin' },
        { text: 'Подключен', value: 'connectedAt' }
      ],
      error: false
    }
  },
  apollo: {
    agentsOnline: {
      query: require('@/graphql/agents-online.gql'),
      error(err) { this.error = err },
      subscribeToMore: {
        document: require('@/graphql/agents-online-subscription.gql'),
        updateQuery: (previousResult, { subscriptionData }) => {
          const updates = subscriptionData.data.agentsOnlineUpdated
          let agentsOnline
          for (const update of updates) {
            if (update.event === 'destroyed') {
              agentsOnline = previousResult.agentsOnline.filter(el => (el.roomId !== update.roomId))
            } else if (update.event === 'created') {
              agentsOnline = [update.agentOnline, ...previousResult.agentsOnline]
            } else {
              console.warn(`Unknown update event`, update)
              agentsOnline = previousResult.agentsOnline
            }
          }
          return { agentsOnline }
        }
      }
    }
  },
  methods: {
    async refresh() {
      this.error = false
      try {
        await this.$apollo.queries.agentsOnline.refetch()
      } catch (e) {
        this.$toast.error(e)
      }
    }
  }
}
</script>

<style scoped>

</style>
